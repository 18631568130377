import { computed, shallowRef } from 'vue-demi';

import { useItemLoader } from '@/api';
import { v3Url } from '@/utils/fetcher';

import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

function responseToItem(response) {
  return response.data.groups || {};
}

const SUPPORTED_GROUPS = Object.freeze(['duedate']);

/**
 * Loader for task group counts.
 *
 * @param {Object} options - Options for the loader.
 * @param {boolean} [options.active=true] - Whether the loader is active.
 * @param {string} options.group - The group type to load counts for.
 * @param {Object} options.params - Additional parameters for the request.
 * @returns {Object}
 */
export default function useTaskGroupCountsLoader({ active: _active = true, group: _group, params: _params }) {
  const active = shallowRef(_active);
  const group = shallowRef(_group);
  const params = shallowRef(_params);

  const {
    state: { item },
    refresh,
  } = useItemLoader({
    url: computed(() => {
      return active.value && SUPPORTED_GROUPS.includes(group.value) ? v3Url(`tasks/groups/${group.value}/counts`) : '';
    }),
    params: computed(() => {
      return { ...(params.value || {}), include: undefined };
    }),
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    // We need to react to all events that could affect the task counts
    if (['task', 'tasklist', 'project', 'projectTasks', 'tasklistTasks'].includes(event.type)) {
      refresh();
    }
  });

  return {
    item,
    refresh,
  };
}
