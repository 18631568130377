import moment from 'moment';
import { computed, shallowRef, unref } from 'vue-demi';
import { v3Url } from '@/utils/fetcher';
import useListLoader from '../base/useListLoader';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';

// gets a list of tasks from an axios response
function responseToItems({ data }) {
  const { tasks: tasksData } = data;
  const {
    projectCategories = {},
    projects = {},
    tasklists = {},
    users = {},
    companies = {},
    stages = {},
    workflows = {},
    teams = {},
    timeTotals = {},
    tags = {},
    taskSequences = {},
    cards = {},
    columns = {},
    comments = {},
    subtaskStats = {},
    lockdowns = {},
    customfieldTasks: _customfieldTasks = {},
    tasks = {}, // holds parentTasks etc.
  } = data.included || {};

  const customfieldTasks = Object.values(_customfieldTasks);
  const commentValues = Object.values(comments);

  for (let i = 0; i < tasksData.length; i += 1) {
    const task = tasksData[i];
    task.startDate = task.startDate ? moment(task.startDate, 'YYYYMMDD') : null;
    task.dueDate = task.dueDate ? moment(task.dueDate, 'YYYYMMDD') : null;
    task.createdAt = task.createdAt ? moment(task.createdAt) : null;
    task.updatedAt = task.updatedAt ? moment(task.updatedAt) : null;
    task.completedOn = task.completedOn ? moment(task.completedOn) : null;
    task.originalDueDate = task.originalDueDate ? moment(task.originalDueDate) : null;
    task.dateLastModified = task.dateLastModified ? moment(task.dateLastModified) : null;
    if (tags && task.tagIds && task.tagIds.length > 0) {
      task.tags = [];
      task.tagIds.forEach((tagId) => {
        if (tags[tagId]) {
          task.tags.push(tags[tagId]);
        }
      });
    }

    task.workflowStages =
      task.workflowStages?.map((wf) => ({
        ...wf,
        workflow: workflows[wf.workflowId],
        stage: stages[wf.stageId],
      })) ?? [];

    if (lockdowns && task.lockdown && lockdowns[task.lockdown.id]) {
      task.lockdown = lockdowns[task.lockdown.id];
    }

    if (timeTotals && timeTotals[task.id]) {
      task.timeTotals = timeTotals[task.id];
    }

    if (taskSequences && task.sequenceId) {
      task.sequence = taskSequences[task.sequenceId];
    }

    if (cards && columns && task.card && cards[task.card.id]) {
      task.column = columns[cards[task.card.id].column.id];
      task.card = cards[task.card.id];
      task.card.column = columns[cards[task.card.id].column.id];
    }

    if (comments) {
      task.comments = commentValues.filter((comment) => comment.objectId === task.id);
    }

    if (task.completedBy && users[task.completedBy]) {
      task.completedBy = users[task.completedBy];
    }

    if (task.createdBy && users[task.createdBy]) {
      task.createdBy = users[task.createdBy];
    }

    if (task.updatedBy && users[task.updatedBy]) {
      task.updatedBy = users[task.updatedBy];
    }

    if (customfieldTasks) {
      task.customfieldTasks = customfieldTasks.filter((field) => field.taskId === task.id);
    }

    if (task.tasklistId && tasklists[task.tasklistId]) {
      task.taskListId = task.tasklistId;
      task.taskList = tasklists[task.tasklistId]; // NOTE: notice the camelCase
      task.tasklist = tasklists[task.tasklistId];
    }

    if (projects && tasklists[task.tasklistId]) {
      task.project = projects[task.tasklist.projectId];
      task.projectId = task.tasklist.projectId;
      task.tasklist.project = projects[task.tasklist.projectId];
      if (companies) {
        task.project.company = companies[task.project.companyId];
      }
    }

    if (task.project && task.project.categoryId && projectCategories) {
      task.project.category = projectCategories[task.project.categoryId];
    }

    for (let j = 0; j < task.assignees?.length; j += 1) {
      const assignee = task.assignees[j];

      if (assignee.type === 'users') {
        if (users[assignee.id]) {
          task.assignees[j] = { ...users[assignee.id], assigneeType: 'users' };
        }
      } else if (assignee.type === 'companies') {
        if (companies[assignee.id]) {
          task.assignees[j] = {
            ...companies[assignee.id],
            assigneeType: 'companies',
          };
        }
      } else if (assignee.type === 'teams') {
        if (teams[assignee.id]) {
          task.assignees[j] = { ...teams[assignee.id], assigneeType: 'teams' };
        }
      }
    }

    if (task.parentTaskId > 0 && tasks[task.parentTaskId]) {
      task.parentTask = tasks[task.parentTaskId];
    }
    task.subtaskStats = subtaskStats[task.id];
  }

  return tasksData;
}

function computeDisplayOrder(task, tasks) {
  if (task.positionAfterTaskId != null) {
    const previousTask = tasks.find(({ id }) => id === task.positionAfterTaskId);
    if (previousTask) {
      return previousTask.displayOrder + 0.5;
    }
    return Number.MIN_SAFE_INTEGER;
  }
  return task.displayOrder ?? Number.MAX_SAFE_INTEGER;
}

function normalizeTask(inputTask, tasks) {
  const newTask = { ...inputTask };
  newTask.id ??= -1;
  newTask.parentTaskId ??= 0;
  // TODO taskListId is deprecated - remove when no longer used
  newTask.tasklistId ??= newTask.taskListId ?? 0;
  // TODO taskListId is deprecated - remove when no longer used
  newTask.taskListId = newTask.tasklistId;
  newTask.projectId ??= 0;
  newTask.assignees ??= [];
  // TODO use current user
  newTask.createdBy ??= {};
  newTask.priority ??= '';
  newTask.tags ??= [];
  newTask.displayOrder = computeDisplayOrder(newTask, tasks);

  // TODO taskList is deprecated - remove when no longer used
  newTask.tasklist ??= newTask.taskList;

  // Try to reuse the actual project and tasklist
  // which are included in the already loaded tasks.
  if (newTask.tasklist == null) {
    for (let i = 0; i < tasks.length; i += 1) {
      const task = tasks[i];
      if (task.tasklistId === newTask.tasklistId) {
        newTask.tasklist = task.tasklist;
        newTask.project ??= task.project;
        break;
      }
    }
  }

  // Fall back to a placeholder task list.
  newTask.tasklist ??= {
    id: newTask.tasklistId,
    name: '',
    displayOrder: 0,
  };

  // TODO taskList is deprecated - remove when no longer used
  newTask.taskList = newTask.tasklist;

  // Fall back to a placeholder project.
  newTask.project ??= {
    id: newTask.projectId,
    name: '',
  };

  return newTask;
}

function orderAscByManual(task1, task2) {
  return (
    task1.project.name.localeCompare(task2.project.name) ||
    task1.taskList.displayOrder - task2.taskList.displayOrder ||
    task1.parentTaskId - task2.parentTaskId ||
    task1.displayOrder - task2.displayOrder
  );
}
function orderDescByManual(task1, task2) {
  return (
    task2.project.name.localeCompare(task1.project.name) ||
    task1.taskList.displayOrder - task2.taskList.displayOrder ||
    task1.parentTaskId - task2.parentTaskId ||
    task1.displayOrder - task2.displayOrder
  );
}

function orderAscByName(task1, task2) {
  return task1.name.localeCompare(task2.name);
}
function orderDescByName(task1, task2) {
  return task2.name.localeCompare(task1.name);
}

function orderAscByDueDate({ dueDate: date1 }, { dueDate: date2 }) {
  // For consistency with the server, tasks without a dueDate are ordered last.
  if (date1 && date1.isValid()) {
    if (date2 && date2.isValid()) {
      return date1.valueOf() - date2.valueOf();
    }
    return -1;
  }
  if (date2 && date2.isValid()) {
    return 1;
  }
  return 0;
}
function orderDescByDueDate({ dueDate: date1 }, { dueDate: date2 }) {
  // For consistency with the server, tasks without a dueDate are ordered last.
  if (date1 && date1.isValid()) {
    if (date2 && date2.isValid()) {
      return date2.valueOf() - date1.valueOf();
    }
    return -1;
  }
  if (date2 && date2.isValid()) {
    return 1;
  }
  return 0;
}

function orderAscByStartDate({ startDate: date1 }, { startDate: date2 }) {
  // For consistency with the server, tasks without a startDate are ordered last.
  if (date1 && date1.isValid()) {
    if (date2 && date2.isValid()) {
      return date1.valueOf() - date2.valueOf();
    }
    return -1;
  }
  if (date2 && date2.isValid()) {
    return 1;
  }
  return 0;
}
function orderDescByStartDate({ startDate: date1 }, { startDate: date2 }) {
  // For consistency with the server, tasks without a startDate are ordered first.
  if (date2 && date2.isValid()) {
    if (date1 && date1.isValid()) {
      return date2.valueOf() - date1.valueOf();
    }
    return -1;
  }
  if (date1 && date1.isValid()) {
    return 1;
  }
  return 0;
}

function orderAscByCreatedDate(task1, task2) {
  return task1.createdAt.valueOf() - task2.createdAt.valueOf();
}
function orderDescByCreatedDate(task1, task2) {
  return task2.createdAt.valueOf() - task1.createdAt.valueOf();
}

function orderAscByPriority({ priority: priority1 }, { priority: priority2 }) {
  // For consistency with the server, tasks without priority are ordered last.
  if (priority1 === priority2) {
    return 0;
  }
  if (priority1 === 'low') {
    return -1;
  }
  if (priority2 === 'low') {
    return 1;
  }
  if (priority1 === 'medium') {
    return -1;
  }
  if (priority2 === 'medium') {
    return 1;
  }
  if (priority1 === 'high') {
    return -1;
  }
  if (priority2 === 'high') {
    return 1;
  }
  return 0; // should never get here
}
function orderDescByPriority({ priority: priority1 }, { priority: priority2 }) {
  // For consistency with the server, tasks without priority are ordered last.
  if (priority1 === priority2) {
    return 0;
  }
  if (priority1 === 'high') {
    return -1;
  }
  if (priority2 === 'high') {
    return 1;
  }
  if (priority1 === 'medium') {
    return -1;
  }
  if (priority2 === 'medium') {
    return 1;
  }
  if (priority1 === 'low') {
    return -1;
  }
  if (priority2 === 'low') {
    return 1;
  }
  return 0; // should never get here
}

function orderAscByTaskListName(task1, task2) {
  return task1.taskList.name.localeCompare(task2.taskList.name);
}
function orderDescByTaskListName(task1, task2) {
  return task2.taskList.name.localeCompare(task1.taskList.name);
}

function orderAscByProject(task1, task2) {
  return task1.project.name.localeCompare(task2.project.name) || task1.taskList.id - task2.taskList.id;
}

function orderDescByProject(task1, task2) {
  return task2.project.name.localeCompare(task1.project.name) || task2.taskList.id - task1.taskList.id;
}

function orderAscByCreatedBy(task1, task2) {
  return (
    task1.createdBy.firstName.localeCompare(task2.createdBy.firstName) ||
    task1.createdBy.lastName.localeCompare(task2.createdBy.lastName)
  );
}
function orderDescByCreatedBy(task1, task2) {
  return (
    task2.createdBy.firstName.localeCompare(task1.createdBy.firstName) ||
    task2.createdBy.lastName.localeCompare(task1.createdBy.lastName)
  );
}

function orderAscByCompletedOn(task1, task2) {
  if (task1.completedOn && task1.completedOn.isValid()) {
    if (task2.completedOn && task2.completedOn.isValid()) {
      // recently completed subtask should be at top
      return task2.completedOn.valueOf() - task1.completedOn.valueOf();
    }
    return -1;
  }
  if (task2.completedOn && task2.completedOn.isValid()) {
    return 1;
  }
  return orderAscByName(task1, task2);
}
function orderDescByCompletedOn(task1, task2) {
  if (task2.completedOn && task2.completedOn.isValid()) {
    if (task1.completedOn && task1.completedOn.isValid()) {
      // recently completed subtask should be at bottom
      return task1.completedOn.valueOf() - task2.completedOn.valueOf();
    }
    return -1;
  }
  if (task1.completedOn && task1.completedOn.isValid()) {
    return 1;
  }
  return orderAscByName(task1, task2);
}

function orderAscByEstimate(task1, task2) {
  return task1.estimateMinutes - task2.estimateMinutes;
}
function orderDescByEstimate(task1, task2) {
  return task2.estimateMinutes - task1.estimateMinutes;
}

function orderByDefault() {
  return 0;
}

/**
 * Loads a list of tasks from the Teamwork v3 API.
 */
export default function useTasksLoader({
  /**
   * The task from which to load subtasks.
   */
  taskId: _taskId,
  /**
   * The task list from which to load tasks.
   */
  tasklistId: _tasklistId,
  // TODO remove it once usage of this property is eliminated
  // Use tasklistId instead.
  taskListId: _taskListId,
  /**
   * The project from which to load tasks.
   */
  projectId: _projectId,
  /**
   * Filtering and sorting params for the request.
   */
  params: _params,
  /**
   * The number of tasks to load.
   */
  count,
  /**
   * The number of tasks to load per request.
   */
  pageSize,
  /**
   * Whether or not to apply the default include values.
   */
  withDefaultIncludes = true,
}) {
  const taskId = shallowRef(_taskId);
  const taskListId = shallowRef(_taskListId !== undefined ? _taskListId : _tasklistId);
  const projectId = shallowRef(_projectId);
  const params = computed(() => {
    const normalizedParams = { ...unref(_params) };
    const { orderBy, orderMode } = normalizedParams;

    if (!orderBy && pageSize > 1) {
      normalizedParams.orderBy = 'manual';
    }

    if (!orderMode && pageSize > 1) {
      normalizedParams.orderMode = 'asc';
    } else if (orderMode !== 'asc' && orderMode !== 'desc') {
      console.warn(`useTasksLoader: unknown params.orderMode: ${orderMode}`);
      normalizedParams.orderMode = 'asc';
    }

    normalizedParams.include = [
      ...new Set(
        (normalizedParams.include || '')
          .split(',')
          .map((item) => item.trim())
          .filter(Boolean)
          .concat(withDefaultIncludes ? ['taskLists', 'taskListNames', 'projectNames', 'projects'] : []),
      ),
    ].join(',');

    return normalizedParams;
  });
  const url = computed(() => {
    if (taskId.value) {
      return v3Url(`tasks/${taskId.value}/subtasks`);
    }
    if (taskListId.value) {
      return v3Url(`tasklists/${taskListId.value}/tasks`);
    }
    if (projectId.value) {
      return v3Url(`projects/${projectId.value}/tasks`);
    }
    return v3Url('tasks');
  });
  const order = computed(() => {
    const { orderBy, orderMode } = params.value;
    const desc = orderMode === 'desc';

    switch (orderBy?.toLowerCase()) {
      case 'manual':
        return orderAscByManual;
      case 'projectmanual':
        return desc ? orderDescByManual : orderAscByManual;
      case 'taskname':
        return desc ? orderDescByName : orderAscByName;
      case 'duedate':
        return desc ? orderDescByDueDate : orderAscByDueDate;
      case 'startdate':
        return desc ? orderDescByStartDate : orderAscByStartDate;
      case 'priority':
        return desc ? orderDescByPriority : orderAscByPriority;
      case 'tasklistname':
        return desc ? orderDescByTaskListName : orderAscByTaskListName;
      case 'createdby':
        return desc ? orderDescByCreatedBy : orderAscByCreatedBy;
      case 'project':
        return desc ? orderDescByProject : orderAscByProject;
      case 'dateadded':
        return desc ? orderDescByCreatedDate : orderAscByCreatedDate;
      case 'active':
        return desc ? orderDescByCompletedOn : orderAscByCompletedOn;
      case 'estimatedtime':
        return desc ? orderDescByEstimate : orderAscByEstimate;
      case 'assignedto':
        return orderByDefault;
      default:
        console.warn(`useTasksLoader: unknown params.orderBy: ${orderBy}`);
        return orderByDefault;
    }
  });

  const { state, refresh, update } = useListLoader({
    url,
    params,
    count,
    responseToItems,
    order,
    pageSize,
  });

  /**
   * Determines if the specified `task` matches the filter params passed into `useTasksLoader`.
   * The implementation is not 100% accurate because it is not currently necessary and
   * would require a lot of extra complexity. In other words, for some combinations of tasks and filters,
   * the result will be incorrect. If that causes issues in the UI, then `matchesFilter` should be adjusted.
   *
   * @params avoidFalsePositives Determines if false positives should be avoided. Defaults to false.
   * If false:
   * - false positives do happen in some situations
   * - false nagatives do not happen
   * If true:
   * - false positives may still happen
   * - false negatives do happen in some situations
   */
  function matchesFilter(task, avoidFalsePositives) {
    if (
      avoidFalsePositives &&
      params.value &&
      (params.value.filterText ||
        params.value['responsible-party-ids'] ||
        params.value.tagIds ||
        params.value.filter === 'completed')
    ) {
      return false;
    }

    // Required for Project > Tasks.
    if (!params.value?.getSubTasks && !taskId.value && task.parentTaskId) {
      return false;
    }

    // Required for Project > Tasks.
    if (taskId.value && taskId.value !== task.parentTaskId) {
      return false;
    }

    // Required for Project > Tasks.
    if (
      taskListId.value &&
      // TODO taskListId is deprecated - remove when no longer used
      taskListId.value !== task.taskListId &&
      taskListId.value !== task.tasklistId
    ) {
      return false;
    }

    // Required for Project > Tasks.
    if (projectId.value && projectId.value !== task.projectId) {
      return false;
    }

    // Required for My Work > Tasks.
    if (params.value && params.value.ignoreStartDates) {
      const today = moment().startOf('day');
      const { dueDate } = task;

      switch (params.value.taskFilter) {
        case 'upcoming':
          if (!dueDate) {
            return false;
          }
          if (params.value.includeToday ?? true ? dueDate.isBefore(today) : dueDate.isBefore(today.add(1, 'day'))) {
            return false;
          }
          break;
        case 'today':
          if (!dueDate) {
            return false;
          }
          if (!dueDate.isSame(today)) {
            return false;
          }
          break;
        case 'overdue':
          if (!dueDate) {
            return false;
          }
          if (dueDate.isSameOrAfter(today)) {
            return false;
          }
          break;
        case 'noduedate':
          if (dueDate) {
            return false;
          }
          break;
        default:
          break;
      }
    }

    return true;
  }

  useOptimisticUpdates((event) => {
    if (event.type === 'task') {
      update((tasks) => {
        if (event.action === 'create') {
          if (matchesFilter(event.task, true)) {
            return tasks.concat([normalizeTask(event.task, tasks)]);
          }
          return tasks;
        }
        if (event.action === 'update') {
          // Remove the task, if it does not match the current filter.
          if (!matchesFilter(event.task)) {
            return tasks.filter((task) => task.id !== event.task.id);
          }

          // Update the existing task.
          if (tasks.some((task) => task.id === event.task.id)) {
            return tasks.map((task) => {
              if (task.id === event.task.id) {
                const newTask = { ...task, ...event.task };
                newTask.displayOrder = computeDisplayOrder(newTask, tasks);
                return newTask;
              }
              return task;
            });
          }

          // Improve UX for task drag and drop.
          if (
            (event.task.previousParentTaskId != null && event.task.previousParentTaskId !== event.task.parentTaskId) ||
            (event.task.previousTaskListId != null && event.task.previousTaskListId !== event.task.taskListId) ||
            (event.task.previousProjectId != null && event.task.previousProjectId !== event.task.projectId)
          ) {
            return tasks.concat([normalizeTask(event.task, tasks)]);
          }

          return tasks;
        }
        if (event.action === 'delete') {
          return tasks.filter((task) => task.id !== event.task.id);
        }
        return tasks;
      }, event.promise);
    }
  });

  useRealTimeUpdates((event) => {
    // If many tasks changed, reload all tasks.
    // The event name is misleading, as task modifications are not limited to a single project.
    if (event.type === 'projectTasks') {
      refresh();
      return;
    }

    // If a task was changed, reload it.
    if (event.type === 'task') {
      refresh(event.taskId);
      return;
    }

    // If filtering by projectId, it must match event.projectId.
    if (projectId.value && projectId.value !== event.projectId) {
      return;
    }

    // If filtering by taskListId, it must match event.taskListId or event.previousTaskListId.
    if (taskListId.value && taskListId.value !== event.taskListId && taskListId.value !== event.previousTaskListId) {
      return;
    }

    // If many tasks in a task list changed, reload all.
    if (event.type === 'taskListTasks') {
      refresh();
      return;
    }

    // If a task list changed.
    if (event.type === 'taskList') {
      // If a side-loaded task list name might have changed, then reload all tasks.
      if (event.action === 'edited') {
        refresh();
      }
      return;
    }

    // Only events of these types can affect task properties.
    if (event.type !== 'task' && event.type !== 'comment' && event.type !== 'time') {
      return;
    }

    // Only added and deleted comments can affect task properties.
    if (event.type === 'comment' && event.action === 'edited') {
      return;
    }

    // If a subtask is added, deleted, completed or reopened,
    // then reload the parent task as its number of subtasks changed.
    if (
      event.type === 'task' &&
      event.parentTaskId &&
      (event.action === 'reopened' ||
        event.action === 'completed' ||
        event.action === 'new' ||
        event.action === 'deleted')
    ) {
      refresh(event.parentTaskId);
    }

    // If loading only one level from the tasks hierarchy...
    if (!params.value.getSubTasks) {
      // If filtering by taskId, it must match event.parentTaskId.
      if (taskId.value && taskId.value !== event.parentTaskId && taskId.value !== event.previousParentTaskId) {
        return;
      }

      // If not loading subtasks, skip subtask changes.
      if (!taskId.value && event.parentTaskId && event.previousParentTaskId) {
        return;
      }
    }

    // Reload the task.
    refresh(event.taskId);
  });

  return state;
}
