import { useIntervalFn, useNow } from '@vueuse/core';
import moment from 'moment';
import { computed, inject, onScopeDispose, provide, shallowRef, unref, watch } from 'vue-demi';
import { useCurrentAccount, useCurrentUser } from '@/api';
import { useLaunchDarkly } from './useLaunchDarkly';
import { useTeamworkFeaturesState } from './useTeamworkFeatures';

const useFeaturesSymbol = Symbol('useFeatures');

/**
 * Related to Craic Dealer experiment
 * @param {string | null | undefined} value
 */
function normalizeFeatureLimit(value) {
  if (value === null || value === '' || value === '-1') {
    return Infinity;
  }
  if (value === undefined) {
    return 0;
  }
  return Number(value);
}

/**
 * Provides LaunchDarkly and Teamwork features.
 */
function FeaturesService() {
  const account = useCurrentAccount();
  const user = useCurrentUser();
  const nowDate = useNow({ interval: 60 * 60 * 1000 });
  const now = computed(() => moment(nowDate.value));

  const { items: twFeaturesList, inSync: twFeaturesInSync } = useTeamworkFeaturesState();
  const twFeaturesInitialized = shallowRef(false);
  const twFeatures = computed(() => {
    const features = new Map();
    twFeaturesList.value.forEach((feature) => {
      features.set(feature.key, feature);
    });
    return features;
  });
  const unwatch = watch(
    twFeaturesInSync,
    () => {
      if (twFeaturesInSync.value) {
        twFeaturesInitialized.value = true;
        queueMicrotask(() => unwatch());
      }
    },
    { immediate: true },
  );

  const { client: ldClient, ready: ldReady, initialized: ldInitialized } = useLaunchDarkly();
  const launchDarklyUpdateCount = shallowRef(0);
  let removeChangeListener;

  function triggerLaunchDarklyUpdate() {
    launchDarklyUpdateCount.value += 1;
  }

  watch([ldReady, ldInitialized], triggerLaunchDarklyUpdate);

  function addChangeListener() {
    const client = ldClient.value;
    if (client) {
      client.on('change', triggerLaunchDarklyUpdate);
      return () => client.off('change', triggerLaunchDarklyUpdate);
    }
    return undefined;
  }

  watch(
    ldClient,
    () => {
      removeChangeListener?.();
      removeChangeListener = addChangeListener();
    },
    { immediate: true },
  );

  // Make sure that the calls to the LaunchDarkly `track` function are recorded,
  // even if the app keeps running for more than 24 hours.
  // See https://docs.launchdarkly.com/home/experimentation/interpreting#understanding-conversions
  useIntervalFn(triggerLaunchDarklyUpdate, 24 * 60 * 60 * 1000);

  onScopeDispose(() => {
    removeChangeListener?.();
    removeChangeListener = undefined;
  });

  // Returns a `computed` which provides a feature flag value from LaunchDarkly.
  function ldFlag(name, defaultValue, overrideValue) {
    return computed(() => {
      if (unref(overrideValue) !== undefined) {
        return unref(overrideValue);
      }
      // Accessing `launchDarklyUpdateCount.value` retriggers the computed when the flag
      // value must be retrieved from LaunchDarkly again.
      if (ldClient.value && launchDarklyUpdateCount.value >= 0) {
        return ldClient.value.variation(name, unref(defaultValue));
      }
      return unref(defaultValue);
    });
  }

  // Returns a `computed` which indicates if a Teamwork feature is enabled.
  /** @returns {{value: boolean}} */
  function twEnabled(name, overrideValue) {
    return computed(() => {
      if (unref(overrideValue) !== undefined) {
        return unref(overrideValue);
      }
      const feature = twFeatures.value.get(name);
      return Boolean(
        feature &&
          (feature.startAt == null || now.value.isAfter(feature.startAt)) &&
          (feature.endAt == null || now.value.isBefore(feature.endAt)) &&
          (feature.scope !== 'ownercompany' || user.value?.inOwnerCompany) &&
          // feature.scope === 'nobody' means "disabled in beta, but potentially togglable"
          feature.scope !== 'nobody',
      );
    });
  }

  /**
   * Returns a `computed` which evaluates to the value of a Teamwork feature.
   * @template T
   * @param {string} name
   * @param {(value: string | null | undefined) => T} normalize
   * @param {T | import('vue').Ref<T>} [overrideValue]
   * @returns {import('vue').ComputedRef<T>}
   */
  function twValue(name, normalize, overrideValue) {
    return computed(() => {
      if (overrideValue !== undefined) {
        return unref(overrideValue);
      }
      const feature = twFeatures.value.get(name);
      return normalize(feature?.value);
    });
  }

  return {
    initialized: computed(() => ldInitialized.value && twFeaturesInitialized.value),

    // LaunchDarkly features
    inboxEnabled: ldFlag('projects-show-inbox-new-notification-bell', false),
    hotspotTourEnabled: ldFlag('projects-hotspot-tour-flag', false),
    hideUpgradePathsForMaxpSmallAccountsEnabled: ldFlag('projects-hide-upgrade-paths-maxp-small-accounts', false),
    addUsersCheckoutModalEnabled: ldFlag('projects-show-add-users-checkout-modal', false),
    isProjectsNewBlankStateEnabled: ldFlag('projects-new-blankstate', false),
    projectsProfilePhotoPromptEnabled: ldFlag('projects-profile-photo-prompt-experiment', false),
    inviteUsersAsAdminsEnabled: ldFlag('projects-invite-users-as-admins', false),
    xeroExportEnabled: ldFlag('projects-xero-export-refresh', false),
    projectsHomeImprovementsEnabled: ldFlag('projects-exp-a-24-13a-home-improvements', false),
    projectsHomeMyWorkTableEnabled: ldFlag('projects-home-my-work-table', false),
    teamworkNextNotebooksEnabled: ldFlag('teamwork-next-notebooks', false),
    deskProjectTicketLinkEnabled: ldFlag('desk-project-ticket-link', false),

    projectsOnboardingDummyExperimentVariation: ldFlag('projects-onboarding-dummy-experiment', 'control'),

    projectsScaleUpgradeSlatesEnabled: ldFlag('projects-scale-upgrade-slates-enabled', false),

    isNewInlineImageEnabled: ldFlag('projects-new-inline-image', false),

    shouldReduceElasticsearchLoad: ldFlag('projects-elasticsearch-should-reduce-load', false),
    noCustomReportingRefreshLimit: ldFlag('projects-custom-reporting-reduced-cutoff', false),
    timeTotalsV3Enabled: ldFlag('projects-time-totals-v3', false),
    hideOldWorkload: ldFlag('projects-only-new-workload', false),
    timeApprovalsFlagEnabled: ldFlag('projects-time-approvals', false),
    newUserProfileEnabled: ldFlag('projects-lightspeed-new-user-profile', false),
    lightspeedListViewEnabled: ldFlag('projects-lightspeed-list-view', false),
    lightspeedListViewToggleEnabled: ldFlag('projects-lightspeed-view-toggle--list-view', false),
    projectListViewBetaToggleDefaultOff: ldFlag('projects-listview-beta-toggle-default-off', false),
    newLogTimeModalEnabled: ldFlag('projects-new-log-time-modal', false),
    projectExpE23SubscriptionChanges: ldFlag('projects-exp-e-23-23a-subscription-changes', false),
    newTimerModalEnabled: ldFlag('projects-new-timer-modal', false),
    hideBoardTriggersEnabled: ldFlag('projects-hide-board-triggers-from-users', false),
    welcomePageV3Enabled: ldFlag('projects-exp-a-23-43-welcome-page-v3', false),
    expensesV2Enabled: ldFlag('projects-expenses-v2', false),
    projectsExportXeroDialogEnabled: ldFlag('projects-export-xero-dialog', false),
    newAddOrEditInvoiceDialogEnabled: ldFlag('projects-new-add-or-edit-invoice-dialog', false),
    newMoveExpenseOrTimelogToInvoiceDialogEnabled: ldFlag(
      'projects-new-move-expense-or-timelog-to-invoice-dialog',
      false,
    ),
    timeExportV3Enabled: ldFlag('projects-time-export-v3', false),

    projectsFinanceOnboardingV2Enabled: ldFlag('projects-exp-a-23-01-finance-onboarding-v2', false),

    projectsDeliverToGrowSurfaceUpgradePaths: ldFlag('projects-exp-e-23-30-dg-upgrade-paths', false),

    projectsOnboardingTemplatesExperimentEnabled: ldFlag('projects-exp-a-23-26-onboarding-templates', false),
    projectsGoalBasedOnboardingExperimentEnabled: ldFlag('projects-exp-a-23-50-goal-based-onboarding', false),
    projectsGoalBasedOnboardingBlankStatesEnabled: ldFlag('projects-exp-a-23-50-gbo-blank-states', false),
    projectsOpenAIEnabled: ldFlag('projects-open-ai-enabled', false),

    onboardingGoalsSelectionRedesignEnabled: ldFlag('projects-exp-23-37-onboarding-goals-selection-redesign', false),
    projectsDummyDataEnabled: ldFlag('projects-exp-a-23-27-dummy-data', false),
    projectsDummyDataV3Enabled: ldFlag('projects-exp-a-23-27-dummy-data-v3', false),
    projectsFeatureTrialScale: ldFlag('projects-exp-e-23-21a-feature-trials-scale', false),
    projectsFeatureTrialGrow: ldFlag('projects-exp-e-23-21b-feature-trials-grow', false),
    projectsFeatureTrialGrowTasklistBudgets: ldFlag('projects-exp-e-23-21b-feature-trials-grow-tasklistbudgets', false),
    projectsFeatureTrialGrowRetainerBudgets: ldFlag('projects-exp-e-23-21b-feature-trials-grow-retainerbudgets', false),
    projectsFeatureTrialGrowTimeReport: ldFlag('projects-exp-e-23-21b-feature-trials-grow-timereport', false),
    projectsFeatureTrialGrowProjectBudgetExpenses: ldFlag(
      'projects-exp-e-23-21b-feature-trials-grow-projectbudgetexpenses',
      false,
    ),
    slackIntegrationDuringOnboarding: ldFlag('projects-exp-a-23-41-slack-integration-during-onboarding', false),
    onboardingSignupSourceSurveyStepEnabled: ldFlag('projects-g-23-07-onboarding-signup-source-survey', false),
    projectsTemplateGalleryEnabled: ldFlag('projects-template-gallery', false),
    contextAwareHelpMenuEnabled: ldFlag('projects-exp-a-23-49-context-aware-help-menu', false),
    projectsCraicDealerMaxProjects: ldFlag('projects-exp-e-23-01-craic-dealer-maxp', false),
    projectsWorkflowsEnabled: ldFlag('projects-workflows', false),
    projectsBlackFridayDeliver: ldFlag('projects-exp-r-23-04-black-friday-deliver', false),
    projectsBlackFridayGrow: ldFlag('projects-exp-r-23-04-black-friday-grow', false),
    projectsBlackFridayOffer: ldFlag('projects-exp-r-23-04-black-friday-offer', false),
    promoteGrowPlanDuringTrialEnabled: ldFlag('projects-exp-a-23-57-promote-grow-plan-during-trial', false),
    addClientCreationToOnboardingFlowEnabled: ldFlag(
      'projects-exp-a-23-62-add-client-creation-to-onboarding-flow',
      false,
    ),
    projectRetainerTemplateFlowEnabled: ldFlag('projects-exp-e-23-32-retainer-template-flow', false),
    budgetsUxImprovements: ldFlag('projects-exp-a-23-63-budgets-ux-improvements', false),
    projectsGlobalWorkflowsEnabled: ldFlag('projects-global-workflows', false),
    defaultLandingPageAfterOnboardingEnabled: ldFlag(
      'projects-exp-a-24-01-default-landing-page-after-onboarding',
      false,
    ),
    displayAccountExecContacDetailsToTrialsEnabled: ldFlag(
      'projects-exp-a-24-08-display-account-exec-contac-details-to-trials',
      false,
    ),
    rolesManyToManyEnabled: ldFlag('projects-roles-many-to-many', false),
    projectCustomReportsPerspectiveNewColumns: ldFlag('projects-customreports-perspective-newcolumns', false),
    projectReportsBillableTargetOption: ldFlag('projects-reports-billabletargetmet-option', false),
    projectsFixedFeeBudgetsEnabled: ldFlag('projects-fixed-fee-budgets', false),
    projectsNewUIRepeatingBudgetsEnabled: ldFlag('projects-bulk-repeat-budgets', false),
    projectsClientTicketsEnabled: ldFlag('projects-client-tickets', false),
    lightspeedOnboardingEnabled: ldFlag('projects-lightspeed-onboarding', false),
    projectsNewTaskListBudgetsEnabled: ldFlag('projects-task-list-budgets-dialog', false),
    projectBudgetIssue: ldFlag('projects-budgets-issue', false),
    projectsRetainerTasklistBudgets: ldFlag('projects-retainer-tasklist-budgets', false),
    projectsBudgetNoConflictEnabled: ldFlag('projects-budget-no-conflict', false),
    projectsFixedFeePermissionEnabled: ldFlag('projects-fixed-fee-permission', false),
    projectsBudgetProfitabilityPermissionEnabled: ldFlag('projects-budget-profitability-permission', false),
    projectsBudgetHistoryLimitEnabled: ldFlag('projects-budget-history-limit', false),
    projectsIntegrationsSalesforceEnabled: ldFlag('projects-integrations-salesforce', false),
    projectsBudgetsCalculationsV2Enabled: ldFlag('projects-budgets-calculations-v2', false),
    projectsSearchV3Enabled: ldFlag('projects-search-v-3', false),
    invoiceSummaryEnabled: ldFlag('projects-invoice-summary', false),
    projectsPreventTaskDeletion: ldFlag('projects-prevent-task-deletion', false),

    // Teamwork features
    activeProjectLimit: twValue('activeprojectlimit', normalizeFeatureLimit),
    activeProjectLimitEnabled: twEnabled('activeprojectlimit'),
    advancedFinancialIntegrationsEnabled: twEnabled('advancedfinancialintegrations'),
    auditTrailEnabled: twEnabled('audittrail'),
    burndownchartLimit: twValue('burndownchart', normalizeFeatureLimit),
    calendarGoogleCalSyncEnabled: twEnabled('googlecalsync'),
    clockInOutEnabled: twEnabled('clockinout'),
    customfieldsLimit: twValue('customfields', normalizeFeatureLimit),
    customfieldsProjectsEnabled: twEnabled('customfieldsprojects'),
    customfieldsTasksEnabled: twEnabled('customfieldstasks'),
    financialBudgetLimit: twValue('projectfinancialbudgets', normalizeFeatureLimit),
    myBoardsEnabled: twEnabled('myboards'),
    newGanttEnabled: twEnabled('newgantt'),
    portfolioLimit: twValue('portfolio', normalizeFeatureLimit),
    plannedVsActualMilestoneReportEnabled: twEnabled('plannedactualmilestonereport'),
    plannedVsActualTasksReportEnabled: twEnabled('plannedactualtasksreport'),
    profitabilityEnabled: twEnabled('profitability'),
    projectBudgetExpensesEnabled: twEnabled('projectbudgetexpenses'),
    projectFinancialBudgetsEnabled: twEnabled('projectfinancialbudgets'),
    projectTemplatesEnabled: twEnabled('projecttemplates'),
    projectTemplatesLimit: twValue('projecttemplates', normalizeFeatureLimit),
    projectTimeBudgetsEnabled: twEnabled('projecttimebudgets'),
    projectTimeReportEnabled: twEnabled('projecttimereport'),
    projectRetainerBudgetsEnabled: twEnabled('retainerbudgets'),
    reportsEnabled: twEnabled('reports'),
    projectsHealthReportEnabled: twEnabled('projectshealthreport'),
    salesforceIntegrationEnabled: twEnabled('salesforceintegration'),
    tasklistBudgetsEnabled: twEnabled('tasklistbudgets'),
    tasklistBudgetsLimit: twValue('tasklistbudgets', normalizeFeatureLimit),
    timeBudgetLimit: twValue('projecttimebudgets', normalizeFeatureLimit),
    anyBudgetsEnabled: twEnabled('budgets'),
    anyBudgetsLimit: twValue('budgets', normalizeFeatureLimit),
    taskTimeReportEnabled: twEnabled('tasktimereport'),
    retainerBudgetLimit: twValue('retainerbudgets', normalizeFeatureLimit),
    fixedFeeBudgetsEnabled: twEnabled('fixedfeebudgets'),
    fixedFeeBudgetLimit: twValue('projectfixedfeebudgets', normalizeFeatureLimit),
    userLoggedTimeReportEnabled: twEnabled('userloggedtimereport'),
    userTaskCompletionReportEnabled: twEnabled('usertaskcompletionreport'),
    utilizationReportEnabled: twEnabled('utilizationreport'),
    timeReportEnabled: twEnabled('timereport'),
    workloadBacklogEnabled: twEnabled('workloadbacklog'),
    previewProfitabilityEnabled: twEnabled('previewprofitability'),
    customReportingEnabled: twEnabled('customreporting'),
    customReportingLimit: twValue('customreporting', normalizeFeatureLimit),
    workloadPlannerEnabled: twEnabled('workloadplanner'),
    intakeFormsLimit: twValue('intakeforms', normalizeFeatureLimit),
    riskRegisterEnabled: twEnabled('riskregister'),
    timeRemindersEnabled: twEnabled('timereminders'),
    timeApprovalsEnabled: twEnabled('timeapprovals'),
    clientUsersEnabled: twEnabled('clientusers'),
    jobRolesEnabled: twEnabled('jobroles'),

    scheduleReportingEnabled: twEnabled('schedulereporting'),
    resourceSchedulePeopleEnabled: twEnabled('resourceschedulingpeople'),
    googleSheetsExportEnabled: twEnabled('accessgooglesheetsexports'),

    aiAssistantEnabled: ldFlag('projects-ai-assistant', false),
  };
}

/**
 * Provides LaunchDarkly and Teamwork features.
 * @returns {void}
 */
export function provideFeatures() {
  provide(useFeaturesSymbol, FeaturesService());
}

/**
 * Provides LaunchDarkly and Teamwork features.
 * @returns {ReturnType<typeof FeaturesService>}
 */
export function useFeatures() {
  return inject(useFeaturesSymbol);
}
